@import 'shared/fonts/AN/fonts.css';

html, body {
    font-family: 'Arial Narrow', 'Franklin Gothic Medium', Arial, sans-serif;
}

iframe {
    border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.tezos-logo {
    width: 21px;
    height: 21px;
    vertical-align: bottom;
}

.tezos-logo--small {
    max-width: fit-content;
    height: 15px;
    vertical-align: bottom;
}

.tezos-logo--orange {
    fill: #FF6800;
}

.ColorPicker-MuiPopover-root .ColorPicker-MuiSlider-root {
    width: 95%;
}
