html,
body,
#root {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

body {
    height: 100%;
    width: 100%;
}
