.react-datepicker {
    position: absolute;
    bottom: 0;
    right: -450px;
    border-radius: 0;
    background: #FFFFFF;
    border: none;
    box-sizing: border-box;
    font-family: 'Arial Narrow', 'Franklin Gothic Medium', Arial, sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}

.react-datepicker__navigation,
.react-datepicker__navigation:hover {
    background-color: #FF6800;
    border-radius: 59px;
    width: 28px;
    height: 28px;
    text-align: center;
}

.react-datepicker__month-container {
    border: 0.2px solid #000000;
    margin-left: 6px;
}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 6px 12px
}

.react-datepicker__header {
    border: none;
    background: #FFFFFF;
}

.react-datepicker__day-names {
    font-weight: normal;
}

.react-datepicker__day:hover {
    border-radius: 59px;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected:hover {
    background-color: #FF6800;
    border-radius: 59px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

.react-datepicker__time-container {
    margin-top: 6px;
    border: none;
    width: 370px;
}

.react-datepicker__time-list-item {
    border: 0.2px solid #000000;
    border-radius: 8px;
    margin: 6px;
    padding: 8px 10px !important;
    line-height: 30px;
}

.react-datepicker__time-list-item--selected {
    background-color: #FF6800 !important;
}

.react-datepicker__time-box,
.react-datepicker__time-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100% !important;
    height: auto !important;
}

.react-datepicker__header--time {
    display: none;
}

.date-input-wrapper {
    position: relative;
}

.custom-datepicker-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.empty-div-for-header {
    width: 41px;
}